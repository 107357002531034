import React, { Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { startLoading } from "../../features/loading/loadSlice";
import MenuItems from "./constans";

const { SubMenu } = Menu;

const Sidebar = (props) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const pathname = location.pathname;
	let defaulS = [];
	defaulS.push(pathname);

	useEffect(() => {
		let token = Cookies.get("access_token");
		if (token) {
			let { from } = location.state || { from: { pathname } };
			history.replace(from);
		} else {
			let { from } = location.state || { from: { pathname: "/login" } };
			history.replace(from);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// 无子级菜单处理
	const renderMenu = ({ text, key, url, icon }) => {
		return (
			<Menu.Item key={key} icon={icon}>
				<NavLink to={url} onClick={() => dispatch(startLoading())}>
					<span>{text}</span>
				</NavLink>
			</Menu.Item>
		);
	};

	// 子级菜单处理
	const renderSubMenu = ({ text, key, icon, submenu }) => {
		return (
			<SubMenu key={key} title={text} icon={icon}>
				{submenu &&
					submenu.map((item) => {
						return item.submenu && item.submenu.length > 0 ? renderSubMenu(item) : renderMenu(item);
					})}
			</SubMenu>
		);
	};

	return (
		<Fragment>
			<Menu theme="light" mode="inline" selectedKeys={defaulS[0]}>
				{MenuItems.map((menu) => {
					return menu.submenu && menu.submenu.length > 0 ? renderSubMenu(menu) : renderMenu(menu);
				})}
			</Menu>
		</Fragment>
	);
};

export default Sidebar;
