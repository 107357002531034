import React, { useEffect } from "react";
import "@/assets/scss/components/layout/main.scss";
import { renderRoutes } from "react-router-config";
import { Toaster, toast } from "react-hot-toast";
import { useHistory, useLocation } from "react-router";
import Cookies from "js-cookie";
import socket_client from "./utils/socket_client";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

export const App = (props) => {
	const { route } = props;
	const history = useHistory();
	const location = useLocation();

	let token = Cookies.get("access_token");

	if (!token) {
		let { from } = location.state || { from: { pathname: "/login" } };
		history.replace(from);
	}

	// 全局监听路由变化：有任何改变时做相应操作
	useEffect(() => {
		leavChannel(); // 关闭可能存在的支付监听
	}, [props.location]);

	const leavChannel = () => {
		let client = socket_client(),
			exists_order = localStorage.getItem("request_order");
		if (exists_order) {
			client.leave(`payment.${exists_order}`);
			client.leave(`payment.failed.${exists_order}`);
			localStorage.removeItem("request_order");
			toast.dismiss();
		}
	};
	return (
		<ConfigProvider locale={zhCN}>
			<div>
				<Toaster position="top-center" toastOptions={{ duration: 1500 }} />
				{renderRoutes(route.childs)}
			</div>
		</ConfigProvider>
	);
};

export default App;
