import { createSlice } from "@reduxjs/toolkit";

export const selectLoading = (state) => state.loading;

export const loadSlice = createSlice({
	name: "loading",
	initialState: {
		value: false,
	},
	reducers: {
		startLoading: (state) => {
			state.value = true;
		},
		endLoading: (state) => {
			state.value = false;
		},
	},
});

export const endLoadingAsync = (state) => (dispatch) => {
	setTimeout(() => {
		dispatch(endLoading(state));
	}, 1000);
};

export const { startLoading, endLoading } = loadSlice.actions;

export default loadSlice.reducer;
