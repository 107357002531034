import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "@/assets/scss/recharge.scss";
import { Form, Radio, Input, Button, PageHeader } from "antd";
import toast from "react-hot-toast";
import paymentApi from "../../api/payment";
import products_api from "../../api/products";
import { startLoading, endLoading } from "../../features/loading/loadSlice";

export const Recharge = (props) => {
	const history = useHistory();
	const [submiting, setSubmiting] = useState(false);
	const [products, setProducts] = useState([]);
	useEffect(() => {
		toast.dismiss(); //清理残留消息框<可能存在拉起支付的消息框>
		startLoading();
		products_api().then((res) => {
			setProducts(res.data.data);
			endLoading();
		});

		// 移除事件监听或取消异步函数的执行，否则会有报错
		// Warning: Can't perform a React state update on an unmounted component.
		return () => {
			setProducts([]);
		};
	}, []);

	const onFinish = (data) => {
		setSubmiting(true);

		paymentApi.payment(data).then((res) => {
			if (res.status === 200) {
				let { from } = {
					from: { pathname: "/business/payment_gateway", search: "order_id=" + res.data.order_id },
				};
				history.replace(from);
				setSubmiting(false);
			} else {
				toast.error(res.data.msg, { duration: 4000 });
				setSubmiting(false);
			}
		});
	};

	return (
		<div className="recharge">
			<PageHeader className="page-header" title="充值中心" />

			<div className="form-container">
				<Form
					name="recharge_form"
					onFinish={onFinish}
					initialValues={{
						product_code: "H01",
						pay_type: "2",
					}}
				>
					<Form.Item
						name="product_code"
						label="充值类型"
						rules={[
							{
								required: true,
								message: "请选择充值类型",
							},
						]}
					>
						<Radio.Group>
							{products.map((item) => (
								<Radio value={item.code} key={item.code}>
									{item.name + "(" + item.description + ")"}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>

					<Form.Item
						name="amount"
						label="充值金额"
						rules={[
							{
								required: true,
								message: "请输入充值金额",
							},
						]}
					>
						<Input placeholder="请输入充值金额" suffix="CNY" />
					</Form.Item>

					<Form.Item
						name="pay_type"
						label="支付方式"
						rules={[
							{
								required: true,
								message: "请选择支付方式",
							},
						]}
					>
						<Radio.Group>
							<Radio value="2" key={2}>
								支付宝
							</Radio>
							<Radio value="6" key={6}>
								聚合支付
							</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" loading={submiting}>
							开始充值
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default Recharge;
