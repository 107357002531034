import React from "react";
import ReactDOM from "react-dom";
import "@/assets/css/reset.css";
import "@/assets//scss//global.scss";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import routes from "./config/routes";

ReactDOM.render(
	<Provider store={store}>
		<Router>{renderRoutes(routes)}</Router>
	</Provider>,
	document.getElementById("root")
);

// 如果你想让你的应用离线工作并加载得更快，你可以改变
// unregister() 到 register() 下面。 请注意，这会带来一些陷阱。
// 了解有关 Service Worker 的更多信息：https://bit.ly/CRA-PWA
serviceWorker.unregister();
