import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../features/customer/customerSlice";
import loadSlice from "../features/loading/loadSlice";

export const store = configureStore({
	reducer: {
		customer: customerReducer,
		loading: loadSlice,
	},
});
