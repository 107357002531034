import React from "react";
import { useHistory, useLocation } from "react-router";
import { Button } from "antd";
import toast from "react-hot-toast";
import customerApi from "../../api/customer";
import Cookies from "js-cookie";

export const Logout = (props) => {
	const history = useHistory();
	const location = useLocation();

	const handleClick = () => {
		customerApi.logout().then((res) => {
			if (res.status === 204) {
				let { from } = location.state || { from: { pathname: "/login" } };
				Cookies.remove("access_token");
				sessionStorage.setItem("login_form", true);
				toast.error("您已退出.");
				setTimeout(() => {
					history.replace(from);
				}, 800);
			} else {
				toast.error("退出失败.");
			}
		});
	};
	return (
		<div className="logout">
			<Button onClick={handleClick}>退出登录</Button>
		</div>
	);
};

export default Logout;
