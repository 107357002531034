import http from "../utils/http";

function payment(data) {
	return http.post("secure_payment", data);
}

function gateway(data) {
	return http.post("gateway", data);
}

function cancel(data) {
	return http.post("cancel_order", data);
}
function undone() {
	return http.post("payment_not_complete");
}
function done() {
	return http.post("payment_history");
}

const paymentApi = {
	payment,
	gateway,
	cancel,
	undone,
	done,
};

export default paymentApi;
