import http from "../utils/http";
import axios from "axios";

const basePath = process.env.REACT_APP_BASE_URL;

function get() {
	return http.get("customer");
}

function put() {}

function logout() {
	return http.delete("authorizations/current");
}

function post(data) {
	return axios.post(basePath + "/customer", data);
}

const customerApi = { get, put, logout, post };

export default customerApi;
