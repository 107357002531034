import React from "react";
import { useState, useEffect } from "react";
import { Table, Button, Pagination, Row, Col, Modal } from "antd";
import paymentApi from "../../api/payment";
import http from "../../utils/http";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import toast from "react-hot-toast";

function NotComplete(props) {
	const [data, setData] = useState([]);
	const [all, setAll] = useState([]);
	// const [hasOrder, setHasOrder] = useState(false);
	// const [continue_submiting, setContinue_submiting] = useState(false);
	const [modelVisible, setModelVisible] = useState(false);
	const [orderId, setOrderId] = useState("");

	const history = useHistory();

	useEffect(() => {
		localStorage.setItem("not.complete.page_size", 15);
		loadDate();
	}, []);

	const loadDate = () => {
		let path = process.env.REACT_APP_BASE_URL;
		let page = localStorage.getItem("not.complete.current_page");
		let pageSize = localStorage.getItem("not.complete.page_size");
		if (page) {
			http.post(path + "/payment_not_complete?page=" + page + "&limit=" + pageSize).then((res) => {
				setAll(res.data.data);
				setData(res.data.data.data);
			});
		} else {
			paymentApi.undone().then((res) => {
				let response_data = res.data.data,
					page_data = response_data.data;
				if (page_data.length) {
					setAll(response_data);
					setData(page_data);
					// setHasOrder(true);
				}
			});
		}
	};

	const handleCancel = function () {
		if (orderId) {
			paymentApi.cancel({ order_id: orderId }).then((res) => {
				if (res.status === 200) {
					toast.success(`订单:${orderId}已取消.`);
					setModelVisible(false);
					setOrderId("");
					loadDate();
				}
			});
		}
	};
	const handlePay = (order_id) => {
		// setContinue_submiting(true);
		setTimeout(() => {
			let { from } = {
				from: { pathname: "/business/payment_gateway", search: "order_id=" + order_id },
			};
			history.replace(from);
		}, 1500);
	};

	const columns = [
		{
			title: "订单编号",
			dataIndex: "order_id",
			key: "order_id",
			width: 250,
		},
		{
			title: "订单金额",
			dataIndex: "order_amount",
			key: "order_amount",
		},
		{
			title: "支付方式",
			dataIndex: "pay_type",
			key: "pay_type",
			render: (text, item) => {
				if (item.pay_type == "2") {
					return "支付宝扫码";
				} else if (item.pay_type == "6") {
					return "聚合支付(汇付天下)";
				}
			},
		},
		{
			title: "订单时间",
			dataIndex: "order_time",
			key: "order_time",
			width: 250,
		},
		{
			title: "产品名称",
			dataIndex: "product_name",
			key: "product_name",
		},
		{
			title: "产品描述",
			dataIndex: "product_desc",
			key: "product_desc",
		},
		{
			title: "订单状态",
			dataIndex: "status",
			key: "status",
			render: (text, item) => {
				if (item.status == "processing") {
					return "等待支付";
				} else if (item.status == "cancel") {
					return "已取消";
				}
			},
		},
		{
			title: "操作",
			dataIndex: "operation",
			key: "operation",
			width: 250,
			render: (text, item) => {
				if (item.status !== "cancel") {
					return (
						<div>
							<Button type="link" danger onClick={onHandlerModelClick.bind(this, item.order_id)}>
								取消订单
							</Button>
							<Button
								type="link"
								onClick={() => {
									handlePay(item.order_id);
								}}
							>
								重新支付
							</Button>
						</div>
					);
				} else {
					return <div>--</div>;
				}
			},
		},
	];

	/** 当前页码 */
	const onChangeCurrnePage = (page, pageSize) => {
		let path = process.env.REACT_APP_BASE_URL;
		http.post(path + "/payment_not_complete?page=" + page + "&limit=" + pageSize).then((res) => {
			setAll(res.data.data);
			setData(res.data.data.data);
		});
	};

	/** 下拉页码 */
	const onChangeSizePage = (current, size) => {
		let path = process.env.REACT_APP_BASE_URL;
		localStorage.setItem("not.complete.page_size", size);
		http.post(path + "/payment_not_complete?page=1&limit=" + size).then((res) => {
			setAll(res.data.data);
			setData(res.data.data.data);
		});
	};

	/** 提示框 */
	const onHandlerModelClick = (id) => {
		setModelVisible(true);
		setOrderId(id);
	};

	return (
		<ErrorBoundary>
			<div>
				{data.length > 0 ? (
					<div>
						<Table
							title={() => {
								return <div>未完成订单列表 {all.total > 0 ? `(${all.total})` : 0}</div>;
							}}
							// rowkey={data.order_id}
							columns={columns}
							dataSource={data}
							pagination={false}
							bordered
							loading={false}
						/>

						<Modal
							title="提示"
							visible={modelVisible}
							onOk={handleCancel}
							// confirmLoading={confirmLoading}
							onCancel={() => {
								setModelVisible(false);
							}}
						>
							<p>确认取消次订单？取消后将无法恢复。</p>
						</Modal>

						<div className="marginTop30">
							<Row>
								<Col span={8}></Col>
								<Col span={8} offset={8}>
									<Pagination
										total={all.total}
										showSizeChanger
										showQuickJumper
										showTotal={(total) => `Total ${total} items`}
										onChange={onChangeCurrnePage}
										onShowSizeChange={onChangeSizePage}
										pageSizeOptions={[10, 15, 20]}
										defaultPageSize={localStorage.getItem("not.complete.page_size")}
										showTitle={true}
									/>
								</Col>
							</Row>
						</div>
					</div>
				) : (
					""
				)}

				{!data.length && <div className="none-order">当前您还没有任何未完成订单</div>}
			</div>
		</ErrorBoundary>
	);
}

export default NotComplete;
