import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import alipayLogo from "../../assets/imgs/payment/standard-logo-alipay.png";
import huifuLogo from "../../assets/imgs/payment/huifulogo.png";
import "../../assets/scss/gateway.scss";
import { Row, Col, Table, Button, PageHeader } from "antd";
import socket_client from "../../utils/socket_client";
import { param_get } from "../../utils/utils";
import paymentApi from "../../api/payment";
import toast from "react-hot-toast";
import ErrorBoundary from "../../components/ErrorBoundary";

export const Gateway = (props) => {
	const [data, setData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState(false);
	console.log(paymentStatus);
	const history = useHistory();
	useEffect(() => {
		const order_id = param_get("order_id");
		paymentApi.gateway({ order_id: order_id }).then((res) => {
			let response_data = res.data.data;
			if (res.status === 200) {
				setData(response_data);
				let arr = [];
				arr.push(response_data);
				setTableData(arr);
				localStorage.setItem("request_order", response_data.order_id);
				toast.loading("等待支付中...", {
					position: "center-center",
					duration: 1800 * 1000,
				});

				// type == 1 开始监听
				let client = socket_client();

				// 成功订单监听
				client.channel(`payment.${response_data.order_id}`).listen(".payment.complete", function (e) {
					setPaymentStatus(true);
					toast.success(
						(t) => (
							<div>
								<span>{"已完成支付,请在<已完成订单>订单中查看"}</span>
								<span>
									<Button
										as="a"
										onClick={() => {
											window.location.href = "/business/transaction";
										}}
									>
										{"查看订单"}
									</Button>
								</span>
							</div>
						),
						{ duration: 10000 }
					);

					client.leave(`payment.${response_data.order_id}`);
					// 将已完成订单页面置于首页
					localStorage.setItem("complete.current_page", 1);
					setTimeout(() => {
						let { from } = {
							from: { pathname: "/business/transaction" },
						};
						history.replace(from);
						toast.dismiss();
					}, 10000);
				});
				// 失败订单监听
				client.channel(`payment.failed.${response_data.order_id}`).listen(".payment.failed", function (e) {
					setPaymentStatus(false);
					toast.error(
						(t) => (
							<div>
								<span>
									{
										"支付异常,请在<未完成订单>中查看,如重新支付无法完成,请联系我们<service@hidream.net>"
									}
								</span>
								<span>
									<Button
										as="a"
										onClick={() => {
											window.location.href = "/business/un_done";
										}}
									>
										{"继续支付"}
									</Button>
								</span>
							</div>
						),
						{
							duration: 10000,
						}
					);
					client.leave(`payment.${response_data.order_id}`);
					// 将未完成订单页面置于首页
					localStorage.setItem("not.complete.current_page", 1);
					setTimeout(() => {
						let { from } = {
							from: { pathname: "/business/undone" },
						};
						history.replace(from);
						toast.dismiss();
					}, 10000);
				});

				// 超时清除
				setTimeout(function () {
					client.leave(`payment.${response_data.order_id}`);
					client.leave(`payment.failed.${response_data.order_id}`);
					toast.dismiss();
					// 修改订单状态为cancel
					paymentApi.cancel({ order_id: response_data.order_id }).then((res) => {
						toast.custom(<div className="cancel_order">订单超时,已取消.</div>);
						let { from } = {
							from: { pathname: "/business/un_done" },
						};
						history.replace(from);
						toast.dismiss();
					});
				}, 1800 * 1000);
			} else {
				toast.error(
					(t) => (
						<div>
							<span>{`${res.data.msg}`}</span>
							<Button
								onClick={() => {
									window.location.href = "/business/un_done";
								}}
							>
								{"重新支付"}
							</Button>
						</div>
					),
					{ duration: 3000 }
				);
				setTimeout(() => {
					window.location.href = "/business/un_done";
				}, 3100);
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleAggregatePayment = (e) => {
		console.log("开始支付");
	};

	const columns = [
		{
			title: "订单号",
			dataIndex: "order_id",
			key: "order_id",
			width: 250,
		},
		{
			title: "订单金额",
			dataIndex: "order_amount",
			key: "order_amount",
			render: (text, item) => {
				return (
					<div>
						<span>CNY {item.order_amount}</span>
					</div>
				);
			},
		},
		{
			title: "产品信息",
			dataIndex: "product_name",
			key: "product_name",
			render: (text, item) => {
				return (
					<div>
						<span>{item.product_name}</span>
						<span>({item.product_desc})</span>
					</div>
				);
			},
		},
	];

	return (
		<ErrorBoundary>
			<PageHeader className="page-header" title="支付信息" />

			<Row className="marginTop30">
				<Col span={18} offset={3}>
					<div className="order_information">
						<Table
							title={() => {
								return <div>收银台</div>;
							}}
							columns={columns}
							dataSource={tableData}
							bordered
							pagination={false}
						/>
					</div>

					<div className="payment_method marginTop30">
						<span>当前支付方式：</span>
						<span>{data.pay_type == "2" ? <img src={alipayLogo} width="100" alt="支付宝扫码" /> : ""}</span>
						<span>{data.pay_type == "6" ? <img src={huifuLogo} alt="汇付天下" width="100" /> : ""}</span>
					</div>

					<div className="payment_final marginTop30">
						{/* 支付宝扫码 */}
						{data.type == 1 ? (
							<div>
								<div className="qrlabel">请扫码支付</div>
								<img src={"data:image/png;base64," + data.qrcode} alt="支付宝扫码支付" />
							</div>
						) : (
							<div>
								<Button
									size="large "
									type="primary"
									href={data.url}
									target="_blank"
									onClick={handleAggregatePayment}
								>
									去支付
								</Button>
							</div>
						)}

						{/* 聚合支付 */}
						{data.pay_type == 6 ? <div></div> : ""}
					</div>
				</Col>
			</Row>
		</ErrorBoundary>
	);
};

export default Gateway;
