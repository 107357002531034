// 防抖:多次触发事件后，事件处理函数只执行一次，并且是在触发操作结束时执行。
export function debounce(fn, delayprop) {
	var delay = delayprop || 2000;
	var timer;
	return function () {
		var th = this;
		var args = arguments;
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(function () {
			timer = null;
			fn.apply(th, args);
		}, delay);
	};
}

// 节流:触发函数事件后，短时间间隔内无法连续调用，只有上一次函数执行后，过了规定的时间间隔，才能进行下一次的函数调用。
export function throttle(fn, intervalprop) {
	var last;
	var timer;
	var interval = intervalprop || 200;
	return function () {
		var th = this;
		var args = arguments;
		var now = +new Date();
		if (last && now - last < interval) {
			clearTimeout(timer);
			timer = setTimeout(function () {
				last = now;
				fn.apply(th, args);
			}, interval);
		} else {
			last = now;
			fn.apply(th, args);
		}
	};
}

// 获取传参
export function param_get(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
}
