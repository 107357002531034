import axios from "axios";

const basePath = process.env.REACT_APP_BASE_URL;

export function auth(data) {
	return axios.post(basePath + "/authorizations", data); // 登录特殊处理
}

function auth_with_phone(data) {
	return axios.post(basePath + "/auth_with_phone", data);
}

function reset_password(data) {
	return axios.post(basePath + "/forgot", data);
}

const anthApi = { auth, auth_with_phone, reset_password };

export default anthApi;
