import io from "socket.io-client";
import Echo from "laravel-echo";
import Cookie from "js-cookie";
/**
 *
 * @method socket_client 返回socket客户端
 */
export default function socket_client(auth = false) {
	let token = Cookie.get("access_token");

	// websocket listen events
	const ECHO = new Echo({
		auth: {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
		broadcaster: "socket.io",
		host: process.env.REACT_APP_SOCKET_URL,
		client: io,
	});

    return ECHO;
}
