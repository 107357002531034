import React from "react";
import { useState, useEffect } from "react";
import { Table, Pagination, Row, Col } from "antd";
import paymentApi from "../../api/payment";
import http from "../../utils/http";
import ErrorBoundary from "../../components/ErrorBoundary";

export const NotComplete = (props) => {
	const [data, setData] = useState([]);
	const [all, setAll] = useState([]);
	const [hasOrder, setHasOrder] = useState(false);
	console.log(hasOrder);

	useEffect(() => {
		let path = process.env.REACT_APP_BASE_URL;
		let page = localStorage.getItem("complete.current_page");
		if (page) {
			http.post(path + "/payment_history?page=" + page).then((res) => {
				setAll(res.data.data);
				setData(res.data.data.data);
			});
		} else {
			paymentApi.done().then((res) => {
				let response_data = res.data.data,
					page_data = response_data.data;
				if (page_data.length) {
					setAll(response_data);
					setData(page_data);
					setHasOrder(true);
				}
			});
		}
	}, []);

	/** 当前页码 */
	const onChangeCurrnePage = (page, pageSize) => {
		let path = process.env.REACT_APP_BASE_URL;
		http.post(path + "/payment_history?page=" + page + "&limit=" + pageSize).then((res) => {
			setAll(res.data.data);
			setData(res.data.data.data);
		});
	};

	/** 下拉页码 */
	const onChangeSizePage = (current, size) => {
		let path = process.env.REACT_APP_BASE_URL;
		localStorage.setItem("not.complete.page_size", size);
		http.post(path + "/payment_history?page=1&limit=" + size).then((res) => {
			setAll(res.data.data);
			setData(res.data.data.data);
		});
	};

	const columns = [
		{
			title: "订单编号",
			dataIndex: "order_id",
			key: "order_id",
			width: 250,
		},
		{
			title: "订单金额",
			dataIndex: "order_amount",
			key: "order_amount",
		},
		{
			title: "支付方式",
			dataIndex: "pay_type",
			key: "pay_type",
			render: (text, item) => {
				if (item.pay_type == "2") {
					return "支付宝扫码";
				} else if (item.pay_type == "6") {
					return "聚合支付(汇付天下)";
				}
			},
		},
		{
			title: "订单时间",
			dataIndex: "order_time",
			key: "order_time",
			width: 250,
		},
		{
			title: "产品名称",
			dataIndex: "product_name",
			key: "product_name",
		},
		{
			title: "产品描述",
			dataIndex: "product_desc",
			key: "product_desc",
		},
	];

	return (
		<div>
			<ErrorBoundary>
				<div>
					{data.length > 0 ? (
						<div>
							<Table
								title={() => {
									return <div>已完成订单列表 {all.total > 0 ? `(${all.total})` : 0}</div>;
								}}
								// rowkey={data.order_id}
								columns={columns}
								dataSource={data}
								pagination={false}
								bordered
								loading={false}
							/>

							<div className="marginTop30">
								<Row>
									<Col span={8}></Col>
									<Col span={8} offset={8}>
										<Pagination
											total={all.total}
											showSizeChanger
											showQuickJumper
											showTotal={(total) => `Total ${total} items`}
											onChange={onChangeCurrnePage}
											onShowSizeChange={onChangeSizePage}
											pageSizeOptions={[10, 15, 20]}
											defaultPageSize={localStorage.getItem("not.complete.page_size")}
											showTitle={true}
										/>
									</Col>
								</Row>
							</div>
						</div>
					) : (
						""
					)}

					{!data.length && <div className="none-order">当前您还没有任何已完成订单</div>}
				</div>
			</ErrorBoundary>
		</div>
	);
};

export default NotComplete;
