import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import "@/assets/scss/login.scss";
import { Form, Input, Button, message, Tabs, Row, Col } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import authApi from "../../api/auth";
import Cookies from "js-cookie";
import SmsLogin from "./SmsLogin";
import { validate_username } from "../../utils/validate";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

function Login(props) {
	const history = useHistory();
	const location = useLocation();
	const [submitLoading, setSubmitLoading] = useState(false);

	useEffect(() => {
		let check_token = Cookies.get("access_token");

		if (!localStorage.getItem("logout")) {
			if (localStorage.getItem("token_expired")) {
				localStorage.removeItem("token_expired");
				localStorage.removeItem("logout");
				message.error("登录状态已过期,请重新登录");
			}
		}
		if (check_token) {
			let { from } = location.state || { from: { pathname: "/" } };
			history.replace(from);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = (data) => {
		setSubmitLoading(true);
		authApi
			.auth(data)
			.then((res) => {
				setSubmitLoading(false);
				if (res.status === 201) {
					message.success("登录成功");
					let expires_time = res.data.expires_in / 60 / 60 / 24,
						{ from } = location.state || { from: { pathname: "/business/customer" } };
					Cookies.set("access_token", res.data.access_token, { expires: expires_time });

					setTimeout(() => {
						history.replace(from);
					}, 1500);
				}
			})
			.catch((error) => {
				setSubmitLoading(false);
				const errResponse = error.response;
				let error_msgs = "",
					msgs = errResponse.data.msg;
				if (typeof msgs == "object") {
					Object.keys(msgs).forEach((key) => {
						return (error_msgs += msgs[key].join(","));
					});
				} else {
					error_msgs = msgs;
				}
				message.error(error_msgs);
			});
	};

	const toggleForm = () => {
		props.switchForm(false);
	};

	return (
		<div className="login">
			<div className="login-container">
				<Tabs defaultActiveKey="1" centered>
					<TabPane tab={<div className="sys-name">账号登录</div>} key="1">
						<div className="login-form marginTop30">
							<Form onFinish={onSubmit}>
								<Form.Item
									name="username"
									validateFirst={true}
									rules={[
										{ required: true, message: "手机号/邮箱不能为空" },
										// { type: "email", message: "邮箱格式不正确" },
										// 自定义验证
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (validate_username(value)) {
													return Promise.resolve();
												}
												return Promise.reject(new Error("手机号/邮箱格式不正确"));
											},
										}),
									]}
								>
									<Input
										prefix={<UserOutlined className="site-form-item-icon" />}
										placeholder="请输入手机号/邮箱"
									/>
								</Form.Item>

								<Form.Item name="password" rules={[{ required: true, message: "密码不能为空" }]}>
									<Input
										type="password"
										prefix={<UnlockOutlined className="site-form-item-icon" />}
										placeholder="请输入密码"
									/>
								</Form.Item>

								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										loading={submitLoading}
										className="login-submit"
									>
										立即登录
									</Button>
								</Form.Item>
							</Form>
						</div>
					</TabPane>

					<TabPane tab={<div className="sys-name">短信登录</div>} key="2">
						<SmsLogin />
					</TabPane>
				</Tabs>

				<Row>
					<Col span={8}>
						<Link to="/reset" style={{ color: "white" }}>
							忘记密码
						</Link>
					</Col>
					<Col span={16}>
						<div className="register-toggle">
							<span>没有账号？</span>
							<span onClick={toggleForm}>去注册</span>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default Login;
