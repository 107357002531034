import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
	name: "customer",
	initialState: {
		logged: false,
		token: "",
	},
	reducers: {
		setLogged(state, { payload }) {
			state.logged = payload.status;
		},
		setLogout(state) {
			state.logged = false;
		},
	},
});

export const setStateAsync = (status) => (dispatch) => {
	setTimeout(() => {
		dispatch(setLogout(status));
	}, 1000);
};

export const { setLogged, setLogout } = customerSlice.actions;

export default customerSlice.reducer;
