import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { renderRoutes } from "react-router-config";
import { Layout, Spin } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import myHeader from "./Header";
import { endLoadingAsync, selectLoading } from "../../features/loading/loadSlice";
import Logo from "../../assets/imgs/hidream-logo.png";
import Sidebar from "./Sidebar";

const { Header, Sider, Content } = Layout;

const MyContent = (props) => {
	const { route } = props;
	const [collapsed, setCollapsed] = useState(false);
	const loading = useSelector(selectLoading);
	const dispatch = useDispatch();

	useEffect(() => {
		if (loading.value) {
			dispatch(endLoadingAsync());
		}
	}, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (sessionStorage.getItem("collapsed")) {
			setCollapsed(JSON.parse(sessionStorage.getItem("collapsed")));
			return;
		}
		sessionStorage.setItem("collapsed", false);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const toggle = () => {
		setCollapsed(!collapsed);
		const collapsed_value = !collapsed;
		sessionStorage.setItem("collapsed", collapsed_value);
	};

	return (
		<div id="components-layout-demo-custom-trigger">
			<Layout className="myLayout ">
				<Sider
					trigger={null}
					collapsed={collapsed}
					collapsedWidth="0"
					// breakpoint="lg" // 990px
					// onBreakpoint={(broken) => {
					// 	if (broken) {
					// 		setCollapsed(true);
					// 		sessionStorage.setItem("collapsed", true);
					// 	} else {
					// 		setCollapsed(false);
					// 		sessionStorage.setItem("collapsed", false);
					// 	}
					// }}
					style={{
						overflow: "auto",
						height: "100vh",
					}}
				>
					<div className="logo">
						<img src={Logo} alt="logo" />
					</div>

					<Sidebar />
				</Sider>

				<Layout
					className="site-layout"
					style={{
						height: "100vh",
					}}
				>
					<Header className="site-layout-background" style={{ padding: 0 }}>
						{React.createElement(myHeader)}

						{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: "trigger",
							onClick: toggle,
						})}
					</Header>

					<Content
						className="site-layout-background"
						style={{
							margin: "24px 16px",
							padding: 24,
							minHeight: 280,
							overflow: "auto",
							height: "100vh",
						}}
					>
						<Spin spinning={loading.value}>{renderRoutes(route.childs)}</Spin>
					</Content>
				</Layout>
			</Layout>
		</div>
	);
};

export default MyContent;
