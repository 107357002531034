import axios from "axios";

const basePath = process.env.REACT_APP_BASE_URL;

// 注册验证码接口
function verifyCode(data) {
	return axios.post(basePath + "/verifyCode", data);
}

//登录验证码接口
function login_code(data) {
	return axios.post(basePath + "/login_code", data);
}

// 忘记密码验证码接口
function forgot_code(data) {
	return axios.post(basePath + "/forgot_code", data);
}

const verifyCodeApi = {
	verifyCode,
	login_code,
	forgot_code,
};

export default verifyCodeApi;
