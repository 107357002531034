import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Form, Input, Button, message, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import authApi from "../../api/auth";
import verifyCodeApi from "../../api/verifyCode";
import Cookies from "js-cookie";
import { validate_phone } from "../../utils/validate";

let timer = null;

const SmsLogin = (props) => {
	const history = useHistory();
	const location = useLocation();
	const [form] = Form.useForm();
	const [submitLoading, setSubmitLoading] = useState(false);
	const [button_disabled, setButton_disabled] = useState(false);
	const [button_text, setButton_text] = useState("获取验证码");
	const [verification_key, setverification_key] = useState("");
	// const [captchaExpired_at, setCaptchaExpiredAt] = useState(""); // 15分钟

	useEffect(() => {
		clearInterval(timer);
		let check_token = Cookies.get("access_token");
		if (check_token) {
			let { from } = location.state || { from: { pathname: "/" } };
			history.replace(from);
		}
		return () => {
			setverification_key("");
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = (data) => {
		setSubmitLoading(true);

		const resData = {
			username: data.username,
			verification_key: verification_key,
			verification_code: data.verification_code,
		};

		authApi
			.auth_with_phone(resData)
			.then((res) => {
				setSubmitLoading(false);
				setverification_key("");
				if (res.status === 201) {
					message.success("登录成功");
					let expires_time = res.data.expires_in / 60 / 60 / 24,
						{ from } = location.state || { from: { pathname: "/business/customer" } };
					Cookies.set("access_token", res.data.access_token, { expires: expires_time });

					setTimeout(() => {
						history.replace(from);
					}, 1500);
				}
			})
			.catch((error) => {
				setSubmitLoading(false);
				const errResponse = error.response;
				let error_msgs = "",
					msgs = errResponse.data.msg;
				if (typeof msgs == "object") {
					Object.keys(msgs).forEach((key) => {
						return (error_msgs += msgs[key].join(","));
					});
				} else {
					error_msgs = msgs;
				}
				message.error(error_msgs);
			});
	};

	/**
	 * 倒计时
	 */
	const countDown = () => {
		// 倒计时时间
		let sec = 60;
		// 修改状态
		setButton_disabled(true);
		setButton_text(`${sec}S`);
		timer = setInterval(() => {
			sec--;
			if (sec <= 0) {
				setButton_disabled(false);
				setButton_text(`重新获取`);
				clearInterval(timer);
				return false;
			}
			setButton_text(`${sec}S`);
		}, 1000);
	};

	/* 获取短信验证码 */
	const getVerifyCode = () => {
		form.validateFields(["username"])
			.then((value) => {
				// 验证通过后进入
				const { username } = value;
				const data = {
					username: username,
				};

				verifyCodeApi.login_code(data).then((res) => {
					if (res.status === 201) {
						setverification_key(res.data.key);
						message.success("短信验证码获取成功");
						countDown();
					} else if (res.status === 429) {
						if (res.retry_after > 0) {
							message.error("短信验证码请求频繁");
						}
					} else {
						let error_msgs = "",
							msgs = res.data.msg;
						if (typeof msgs == "object") {
							Object.keys(msgs).forEach((key) => {
								return (error_msgs += msgs[key].join(","));
							});
						} else {
							error_msgs = msgs;
						}

						message.error(error_msgs);
					}
				});
			})
			.catch((errors) => {
				message.error(errors.errorFields[0].errors);
			});
	};

	return (
		<div className="login-form marginTop30">
			<Form form={form} onFinish={onSubmit}>
				<Form.Item
					name="username"
					validateFirst={true}
					rules={[
						{ required: true, message: "手机号不能为空" },
						({ getFieldValue }) => ({
							validator(rule, value) {
								if (validate_phone(value)) {
									return Promise.resolve();
								}
								return Promise.reject(new Error("手机号格式不正确"));
							},
						}),
					]}
				>
					<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
				</Form.Item>

				<Form.Item
					name="verification_code"
					validateFirst={true}
					rules={[
						{ required: true, message: "短信验证码不能为空" },
						({ getFieldValue }) => ({
							validator(rule, value) {
								let username_value = getFieldValue("username");
								if (!username_value) {
									return Promise.reject(new Error("请输入手机号"));
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Row>
						<Col span={16}>
							<Input placeholder="请输入短信验证码" />
						</Col>

						<Col span={8}>
							<Button
								type="primary"
								disabled={button_disabled}
								onClick={getVerifyCode}
								style={{ width: "100%" }}
							>
								{button_text}
							</Button>
						</Col>
					</Row>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" loading={submitLoading} className="login-submit">
						立即登录
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default SmsLogin;
