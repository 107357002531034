import { UserOutlined, DollarOutlined, FileSearchOutlined } from "@ant-design/icons";

const MenuItems = [
	{
		text: "用户中心",
		key: "/business/customer",
		url: "/business/customer",
		icon: <UserOutlined />,
		submenu: [],
		roles: [],
	},
	{
		text: "充值中心",
		key: "/business/recharge",
		url: "/business/recharge",
		icon: <DollarOutlined />,
		submenu: [],
		roles: [],
	},
	{
		text: "已完成订单",
		key: "/business/transaction",
		url: "/business/transaction",
		icon: <FileSearchOutlined />,
		submenu: [],
		roles: [],
	},
	{
		text: "未完成订单",
		key: "/business/un_done",
		url: "/business/un_done",
		icon: <FileSearchOutlined />,
		submenu: [],
		roles: [],
	},
	// {
	// 	text: "二级菜单",
	// 	key: "/business/test",
	// 	icon: <UserOutlined />,
	// 	submenu: [
	// 		{
	// 			text: "二级菜单1",
	// 			key: "/business/test1",
	// 			url: "/business/test1",
	// 			submenu: [],
	// 			role: [],
	// 		},
	// 		{
	// 			text: "二级菜单2",
	// 			key: "/business/test2",
	// 			url: "/business/test2",
	// 			submenu: [],
	// 			role: [],
	// 		},
	// 	],
	// 	roles: [],
	// },
];

export default MenuItems;
