import React, { Component } from "react";

// 定时器
let timer = null;

class PaymentAccept extends Component {
	constructor(props) {
		super(props);
		this.state = {
			text: "5S",
		};
	}

	componentDidMount() {
		// 倒计时时间
		let sec = 5;
		timer = setInterval(() => {
			sec--;
			this.setState({
				text: `${sec}S`,
			});
			if (sec <= 0) {
				clearInterval(timer);
				this.closeWindow();
			}
		}, 1000);
	}

	closeWindow = () => {
		if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
			window.location.href = "about:blank";
			window.close();
		} else {
			window.opener = null;
			window.open("", "_self");
			window.close();
		}
	};

	render() {
		return (
			<div>
				<div id="ShowTime" align="center">
					充值结束，倒计时{this.state.text}后关闭当前窗口
				</div>
				<input
					type="button"
					name="CloseWindow"
					onClick={() => {
						this.closeWindow();
					}}
					value="立即关闭当前网页"
				/>
			</div>
		);
	}
}

export default PaymentAccept;
