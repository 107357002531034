import React, { Component } from "react";
// 组件
import LoginForm from "./LoginForm";
import RetisterForm from "./RegisterForm";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			login_form: true,
		};
	}

	componentDidMount() {
		if (sessionStorage.getItem("login_form")) {
			const login_form = JSON.parse(sessionStorage.getItem("login_form"));
			this.setState({ login_form: login_form });
			return;
		}
		sessionStorage.setItem("login_form", true);
	}

	// 登录注册组件切换
	switchForm = (value) => {
		this.setState({
			login_form: value,
		});
		const login_form = !this.state.login_form;
		sessionStorage.setItem("login_form", login_form);
	};

	render() {
		return (
			<div className="login-wrapper">
				{this.state.login_form ? (
					<LoginForm switchForm={this.switchForm}></LoginForm>
				) : (
					<RetisterForm switchForm={this.switchForm}></RetisterForm>
				)}
			</div>
		);
	}
}

export default Login;
