import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Cookies from "js-cookie";
import "@/assets/scss/login.scss";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
// 验证
import { validate_username } from "../../utils/validate";
import customerApi from "../../api/customer";
import captcha from "../../api/captcha";
import verifyCodeApi from "../../api/verifyCode";
import toast, { Toaster } from "react-hot-toast";
// import Captcha from "../../components/login/captcha";
// import { debounce } from "../../utils/utils";

const Register = (props) => {
	// ref获取子组件
	// const ref = useRef();

	// 定时器
	let timer = null;
	const [form] = Form.useForm();
	const history = useHistory();
	const location = useLocation();
	// loading
	const [submitLoading, setSubmitLoading] = useState(false);
	const [captchaLoading, setCaptchaLoading] = useState(true);
	const [button_disabled, setButton_disabled] = useState(false);
	const [button_text, setButton_text] = useState("获取验证码");

	const [captcha_key, setcaptcha_key] = useState("");
	// const [captchaCode, setCaptchaCode] = useState("");
	const [captchaExpired_at, setCaptchaExpiredAt] = useState(""); // 5 分钟
	const [captcha_image_content, setcaptcha_image] = useState("");
	const [verification_key, setverification_key] = useState(""); // 5 分钟过期

	useEffect(() => {
		let check_token = Cookies.get("access_token");

		if (check_token) {
			let { from } = location.state || { from: { pathname: "/" } };
			history.replace(from);
		}

		refreshCaptcha();
		clearInterval(timer);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = async (data) => {
		setSubmitLoading(true);

		let getExpiredTime = new Date(captchaExpired_at).getTime();
		let newDate = new Date().getTime();

		if (getExpiredTime < newDate) {
			toast.error("图形验证码已过期,请重新获取");
		}

		const registerData = {
			username: data.username,
			password: data.password,
			password_confirmation: data.password,
			verification_key: verification_key,
			verification_code: data.verification_code,
		};

		await customerApi.post(registerData).then((res) => {
			if (res.status === 200) {
				setSubmitLoading(false);
				toast.success("注册成功");

				let expires_time = res.data.expires_in / 60 / 60 / 24,
					{ from } = location.state || { from: { pathname: "/business/customer" } };
				Cookies.set("access_token", res.data.access_token, { expires: expires_time });

				setTimeout(() => {
					history.replace(from);
				}, 1500);
			} else {
				let error_msgs = "",
					msgs = res.data.msg;
				if (typeof msgs == "object") {
					Object.keys(msgs).forEach((key) => {
						return (error_msgs += msgs[key].join(","));
					});
				} else {
					error_msgs = msgs;
				}
				toast.error(error_msgs);
			}
		});
	};

	const refreshCaptcha = async () => {
		setCaptchaLoading(true);
		await captcha().then((res) => {
			if (res.status === 201) {
				setCaptchaLoading(false);
				setcaptcha_image(res.data.captcha_image_content);
				setcaptcha_key(res.data.captcha_key);
				// setCaptchaCode(res.data.captcha_code);
				setCaptchaExpiredAt(res.data.expired_at);
			} else {
				let error_msgs = "",
					msgs = res.data.msg;
				if (typeof msgs == "object") {
					Object.keys(msgs).forEach((key) => {
						return (error_msgs += msgs[key].join(","));
					});
				} else {
					error_msgs = msgs;
				}
				toast.error(error_msgs);
			}
		});
	};

	// useEffect(() => {
	// 	debounce(() => {
	// 		if (captcha_code && captcha_code !== captchaCode) {
	// 			setError("captcha_code", { type: "issameas" });
	// 		}
	// 		if (captcha_code === captchaCode) {
	// 			clearErrors("captcha_code");
	// 		}
	// 	})();
	// });

	/**
	 * 倒计时
	 */
	const countDown = () => {
		// 倒计时时间
		let sec = 60;
		// 修改状态
		setButton_disabled(true);
		setButton_text(`${sec}S`);
		timer = setInterval(() => {
			sec--;
			if (sec <= 0) {
				setButton_disabled(false);
				setButton_text(`重新获取`);
				clearInterval(timer);
				return false;
			}
			setButton_text(`${sec}S`);
		}, 1000);
	};

	/* 验证码刷新 */
	const getCode = () => {
		form.validateFields(["username", "captcha_code"])
			.then((value) => {
				// 验证通过后进入
				const { username, captcha_code } = value;
				const data = {
					username: username,
					captcha_key: captcha_key,
					captcha_code: captcha_code,
				};

				// API获取短信验证码
				verifyCodeApi.verifyCode(data).then((res) => {
					if (res.status === 201) {
						setverification_key(res.data.key);
						toast.success("短信验证码获取成功");
						countDown();
					} else if (res.status === 429) {
						if (res.retry_after > 0) {
							toast.error("短信验证码请求频繁");
						}
					} else {
						let error_msgs = "",
							msgs = res.data.msg;
						if (typeof msgs == "object") {
							Object.keys(msgs).forEach((key) => {
								return (error_msgs += msgs[key].join(","));
							});
						} else {
							error_msgs = msgs;
						}

						toast.error(error_msgs);
					}
				});
			})
			.catch((errors) => {
				// 获取第一条验证信息
				toast.error(errors.errorFields[0].errors);
			});
	};

	const toggleForm = () => {
		props.switchForm(true);
	};

	return (
		<div className="login">
			<Toaster position="top-center" toastOptions={{ duration: 1500 }} />

			<div className="login-container">
				<h1 className="sys-name">注册</h1>

				<div className="login-form marginTop30">
					<Form form={form} onFinish={onSubmit}>
						<Form.Item
							name="username"
							validateFirst={true}
							rules={[
								{ required: true, message: "手机号/邮箱不能为空" },
								// 自定义验证
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (validate_username(value)) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("手机号/邮箱格式不正确"));
									},
								}),
							]}
						>
							<Input
								prefix={<UserOutlined className="site-form-item-icon" />}
								placeholder="请输入手机号/邮箱"
							/>
						</Form.Item>

						<Form.Item name="captcha_code" rules={[{ required: true, message: "图形验证码不能为空" }]}>
							<Row>
								<Col span={16}>
									<Input placeholder="请输入图形验证码" />
								</Col>

								<Col span={8} className="captcha-wrapper">
									{captchaLoading ? (
										<Spin className="captcha-spinning" />
									) : (
										<div className="captcha_pic" onClick={refreshCaptcha}>
											{captcha_image_content && (
												<img
													src={captcha_image_content}
													alt="图形验证码"
													style={{ width: "100%" }}
												/>
											)}
											{/* <Captcha ref={ref} /> */}
										</div>
									)}
								</Col>
							</Row>
						</Form.Item>

						<Form.Item
							name="verification_code"
							validateFirst={true}
							rules={[
								{ required: true, message: "短信验证码不能为空" },
								// 自定义验证
								({ getFieldValue }) => ({
									validator(rule, value) {
										let username_value = getFieldValue("username"); // 获取username的值
										let captcha_code_value = getFieldValue("captcha_code"); // 获取captcha_code的值
										if (!username_value) {
											return Promise.reject(new Error("请输入手机号/邮箱"));
										}
										if (!captcha_code_value) {
											return Promise.reject(new Error("请输入图形验证码"));
										}
										return Promise.resolve(); // 使用自定义验证返回promise
									},
								}),
							]}
						>
							<Row>
								<Col span={16}>
									<Input placeholder="请输入短信验证码" />
								</Col>

								<Col span={8}>
									<Button
										type="primary"
										disabled={button_disabled}
										onClick={getCode}
										style={{ width: "100%" }}
									>
										{button_text}
									</Button>
								</Col>
							</Row>
						</Form.Item>

						<Form.Item name="password" rules={[{ required: true, message: "密码不能为空" }]}>
							<Input
								type="password"
								prefix={<UnlockOutlined className="site-form-item-icon" />}
								placeholder="请输入密码"
							/>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" loading={submitLoading} className="login-submit">
								注册
							</Button>
						</Form.Item>
					</Form>

					<div className="login-toggle">
						<span onClick={toggleForm}>已有帐号，返回登陆</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
