import Login from "../pages/login/Index";
import ResetPassword from "../pages/login/ResetPassword";
import Customer from "../pages/customer/Customer";
import App from "../App";
import Content from "../components/layout/Content";
import Transaction from "../pages/customer/Transaction";
import NotFound from "../pages/error/NotFound";
import Recharge from "../pages/customer/Recharge";
import Gateway from "../pages/customer/Gateway";
import NotComplete from "../pages/customer/NotComplete";
import PaymentAccept from "../pages/PaymentAccept";

const routes = [
	{
		path: "/login",
		component: Login,
		exact: true,
	},
	{
		path: "/business/payment_accept",
		component: PaymentAccept,
	},
	{
		path: "/reset",
		component: ResetPassword,
	},
	{
		component: App,
		childs: [
			{
				component: Content,
				childs: [
					{
						path: "/",
						exact: true,
						component: Customer,
					},
					{
						path: "/business/customer",
						component: Customer,
					},
					{
						path: "/business/transaction",
						component: Transaction,
					},
					{
						path: "/business/recharge",
						component: Recharge,
					},
					{
						path: "/business/payment_gateway",
						component: Gateway,
					},
					{
						path: "/business/un_done",
						component: NotComplete,
					},
					{
						component: NotFound,
					},
				],
			},
		],
	},
];

export default routes;
