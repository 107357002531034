import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import authApi from "../../api/auth";
import verifyCodeApi from "../../api/verifyCode";
import { validate_username } from "../../utils/validate";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

let timer = null;

function ResetPassword(props) {
	const history = useHistory();
	const location = useLocation();
	const [form] = Form.useForm();
	const [submitLoading, setSubmitLoading] = useState(false);
	const [button_disabled, setButton_disabled] = useState(false);
	const [button_text, setButton_text] = useState("获取验证码");
	const [verification_key, setverification_key] = useState("");

	useEffect(() => {
		clearInterval(timer);
		let check_token = Cookies.get("access_token");
		if (check_token) {
			let { from } = location.state || { from: { pathname: "/" } };
			history.replace(from);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = (data) => {
		setSubmitLoading(true);

		const resData = {
			confirm_password: data.confirm_password,
			new_password: data.new_password,
			username: data.username,
			verification_code: data.verification_code,
			verification_key: verification_key,
		};

		authApi
			.reset_password(resData)
			.then((res) => {
				if (res.status === 200) {
					setSubmitLoading(false);
					setverification_key("");
					form.resetFields();
					message.success("密码修改成功,请返回登录");
				}
			})
			.catch((error) => {
				setSubmitLoading(false);
				const errResponse = error.response;
				let error_msgs = "",
					msgs = errResponse.data.msg;
				if (typeof msgs == "object") {
					Object.keys(msgs).forEach((key) => {
						return (error_msgs += msgs[key].join(","));
					});
				} else {
					error_msgs = msgs;
				}
				message.error(error_msgs);
			});
	};

	/**
	 * 倒计时
	 */
	const countDown = () => {
		// 倒计时时间
		let sec = 60;
		// 修改状态
		setButton_disabled(true);
		setButton_text(`${sec}S`);
		timer = setInterval(() => {
			sec--;
			if (sec <= 0) {
				setButton_disabled(false);
				setButton_text(`重新获取`);
				clearInterval(timer);
				return false;
			}
			setButton_text(`${sec}S`);
		}, 1000);
	};

	/* 获取短信验证码  */
	const getVerifyCode = () => {
		form.validateFields(["username"])
			.then((value) => {
				// 验证通过后进入
				const { username } = value;
				const data = {
					username: username,
				};

				// API获取短信验证码
				verifyCodeApi.forgot_code(data).then((res) => {
					if (res.status === 201) {
						setverification_key(res.data.key);
						message.success("短信验证码获取成功");
						countDown();
					} else if (res.status === 429) {
						if (res.retry_after > 0) {
							message.error("短信验证码请求频繁");
						}
					} else {
						let error_msgs = "",
							msgs = res.data.msg;
						if (typeof msgs == "object") {
							Object.keys(msgs).forEach((key) => {
								return (error_msgs += msgs[key].join(","));
							});
						} else {
							error_msgs = msgs;
						}

						message.error(error_msgs);
					}
				});
			})
			.catch((errors) => {
				// 获取第一条验证信息
				message.error(errors.errorFields[0].errors);
			});
	};

	return (
		<div className="login">
			<div className="login-container">
				<div className="sys-name">重置密码</div>
				<div className="marginTop30">
					<Form form={form} onFinish={onSubmit}>
						<Form.Item
							name="username"
							validateFirst={true}
							rules={[
								{ required: true, message: "手机号/邮箱不能为空" },
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (validate_username(value)) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("手机号/邮箱格式不正确"));
									},
								}),
							]}
						>
							<Input
								prefix={<UserOutlined className="site-form-item-icon" />}
								placeholder="请输入手机号/邮箱"
							/>
						</Form.Item>

						<Form.Item
							name="verification_code"
							validateFirst={true}
							rules={[
								{ required: true, message: "短信验证码不能为空" },
								({ getFieldValue }) => ({
									validator(rule, value) {
										let username_value = getFieldValue("username");
										if (!username_value) {
											return Promise.reject(new Error("请输入手机号/邮箱"));
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Row>
								<Col span={16}>
									<Input placeholder="请输入短信验证码" />
								</Col>

								<Col span={8}>
									<Button
										type="primary"
										disabled={button_disabled}
										onClick={getVerifyCode}
										style={{ width: "100%" }}
									>
										{button_text}
									</Button>
								</Col>
							</Row>
						</Form.Item>

						<Form.Item name="new_password" rules={[{ required: true, message: "新密码不能为空" }]}>
							<Input
								type="password"
								prefix={<UnlockOutlined className="site-form-item-icon" />}
								placeholder="请输入新密码"
							/>
						</Form.Item>

						<Form.Item name="confirm_password" rules={[{ required: true, message: "新密码不能为空" }]}>
							<Input
								type="password"
								prefix={<UnlockOutlined className="site-form-item-icon" />}
								placeholder="请再次输入新密码"
							/>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" loading={submitLoading} className="login-submit">
								确认
							</Button>
						</Form.Item>

						<div className="textAlignRight">
							<Link to="/login">
								<span>去登录</span>
							</Link>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
