import React, { useState, useEffect } from "react";
import customerApi from "../../api/customer";
import { Popover, Image, Avatar, Spin, Divider } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import toast from "react-hot-toast";
import Logout from "../customer/Logout";

const Header = () => {
	const [loaded, setLoaded] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		customerApi.get().then((res) => {
			if (res.status === 200) {
				setData(res.data.data);
				setLoaded(true);
			} else if (res.status === 429) {
				toast.error(res.data.msg);
			}
		});
	}, []);

	return (
		<div className="header">
			<div className="header--left"></div>
			<div className="header--right">
				<div className="shortname">
					{loaded ? <div>您好, {data.email ? data.email : data.username}</div> : <Spin size="small" />}
				</div>

				<div>
					<Popover content={data ? PopoverContent(data) : PopoverContent()} trigger="click">
						<Avatar
							src={
								<Image src="https://joeschmoe.io/api/v1/random" preview={false} style={{ width: 32 }} />
							}
						/>
						<CaretDownFilled style={{ color: "gray" }} />
					</Popover>
				</div>
			</div>
		</div>
	);
};

export default Header;

const PopoverContent = (data) => (
	<div className="dpehyt">
		{data ? (
			<div className="info">
				<div className="left">
					<Avatar
						src={<Image src="https://joeschmoe.io/api/v1/random" preview={false} style={{ width: 32 }} />}
					/>
				</div>
				<div className="right">
					<div className="email">{data.email}</div>
					<div className="username">{data.username}</div>
					<div className="status">
						{data.verified == 1 ? (
							<div>身份已认证</div>
						) : (
							<div>
								身份未认证, <span>立即认证</span>
							</div>
						)}
					</div>
				</div>
			</div>
		) : (
			""
		)}
		<Divider dashed className="divider" />
		<Logout />
	</div>
);
