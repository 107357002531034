/**
 * 网络请求配置
 */
import axios from "axios";
import Cookies from "js-cookie";

let baseURL = process.env.REACT_APP_BASE_URL;

const http = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest",
	},
	timeout: 5000,
});

/**
 * http request 拦截器
 */
http.interceptors.request.use(
	(config) => {
		const access_token = Cookies.get("access_token");
		if (access_token) {
			config.headers["Authorization"] = `Bearer ${access_token}`;
		} else {
			localStorage.setItem("token_expired", true);
			localStorage.setItem("logout", true);
			window.location.href = "/login";
		}

		config.data = JSON.stringify(config.data);

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

/**
 * http response 拦截器
 */
http.interceptors.response.use(
	(response) => {
		if (response.data.errCode == 2) {
			console.log("过期");
		}
		return response;
	},
	(error) => {
		return error.response;
	}
);

export default http;
