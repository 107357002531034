import React from "react";

class ErrorBoundary extends React.Component {
	state = { error: false };

	static getDerivedStateFromError(error) {
		return { error };
	}

	componentDidCatch(error, errorInfo) {
		if (this.props.onError) {
			this.props.onError(error, errorInfo.componentStack);
		}
	}

	render() {
		if (this.state.error) {
			return <h1>页面超时, 请刷新页面.</h1>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
